import { useEffect, useState } from 'react'

const useLockBodyScroll = (elementRef, callback = null) => {
  const [startPoint, setStartPoint] = useState(0)
  const [scrollValue, setScrollValue] = useState(0)

  useEffect(() => {
    const elementOffset = elementRef.current.getBoundingClientRect()
    setStartPoint(elementOffset.top - 50)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  })

  const listenToScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const topOffset = Math.min(window.innerHeight, startPoint)
    const bottomOffset = scrollTop + topOffset
    setScrollValue({
      st: ((bottomOffset - startPoint) / window.innerHeight) * 40
    })
  }

  if (callback) {
    return callback(scrollValue)
  }

  return scrollValue
}

export default useLockBodyScroll
