import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { fluidRange } from 'polished'

const StyledParagraph = styled.p`
  line-height: ${({ theme }) => theme.font.lineHeight};
  margin-top: 0;
  margin-bottom: 0;

  ${({ spaced, noMargin }) => noMargin !== false && noMargin !== 'top' &&
    fluidRange({
      prop: 'margin-top',
      fromSize: spaced ? '1.5em' : '0.8em',
      toSize: spaced ? '2.5em' : '1.2em'
    },
    '300px',
    '1600px'
  )}

  ${({ spaced, noMargin }) => noMargin !== false && noMargin !== 'bottom' &&
    fluidRange({
      prop: 'margin-bottom',
      fromSize: spaced ? '1.5em' : '0.8em',
      toSize: spaced ? '2.5em' : '1.2em'
    },
    '300px',
    '1600px'
  )}

  ${({ small, theme }) => small && `
    font-size: 0.9em;
    color: ${theme.color.grey.mediumDark};
  `}

  ${({ subTitle, theme }) => subTitle && `
    text-transform: uppercase;
    color: ${theme.color.primary};
    font-weight: 600;
    letter-spacing: 0.1em;
    margin: 0.5em 0;
  `}
`

const Paragraph = ({ small, children, noMargin, subTitle, spaced }) => (
  <StyledParagraph
    small={small}
    noMargin={noMargin}
    subTitle={subTitle}
    spaced={spaced}
  >
    {children}
  </StyledParagraph>
)

Paragraph.propTypes = {
  small: PropTypes.bool,
  children: PropTypes.node,
  noMargin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  subTitle: PropTypes.bool,
  spaced: PropTypes.bool
}

export default Paragraph
