import React from 'react'
import MenuItem from './menu-item'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Nav = styled.nav`
  @media(max-width: ${({ theme }) => theme.breakpoint.large}) {
    display: none;
  }
`

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  line-height: 1;
`

const Navigation = ({ dark, navItems }) => (
  <Nav>
    <Ul>
      {navItems.map((navItem, i) =>
        <MenuItem key={i} dark={dark} navItem={navItem} />
      )}
    </Ul>
  </Nav>
)

Navigation.propTypes = {
  dark: PropTypes.bool,
  navItems: PropTypes.array.isRequired
}

export default Navigation
