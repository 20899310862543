import { useState, useCallback } from 'react'

const useHover = () => {
  const [hover, setHover] = useState(false)

  const toggleHover = useCallback(toggleState => {
    setHover(toggleState !== undefined ? Boolean(toggleState) : !hover)
  }, [hover])

  return [hover, toggleHover]
}

export default useHover
