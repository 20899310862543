import { useSpring } from 'react-spring'

const useAnimation = () => {
  const fadeIn = useSpring({
    from: { opacity: '0' },
    opacity: '1'
  })

  return {
    fadeIn
  }
}

export default useAnimation
