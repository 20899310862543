import React from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring, config } from 'react-spring'

const AnimatedAside = ({ children, trigger, right, onLoad, className }) => {
  const offset = right ? '-100px' : '100px'

  const fadeIn = useSpring({
    config: config.molasses,
    opacity: (onLoad || trigger) ? '1' : '0',
    transform: (onLoad || trigger) ? 'translateX(0)' : `translateX(${offset})`
  })

  return (
    <aside className={className}>
      <animated.div style={fadeIn}>
        {children}
      </animated.div>
    </aside>
  )
}

AnimatedAside.propTypes = {
  children: PropTypes.node,
  trigger: PropTypes.bool,
  right: PropTypes.bool,
  onLoad: PropTypes.bool,
  className: PropTypes.string
}

export default AnimatedAside
