import React, { Children, cloneElement, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useIsInViewport from 'use-is-in-viewport'
import useTrigger from '../hooks/useTrigger'
import { fluidRange } from 'polished'

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
  ${({ center }) => center && 'align-items: center'};

  aside {
    z-index: 2;
    max-width: 700px;
    width: 50%;
    margin: 0;
    @media(min-width: ${({ theme }) => theme.breakpoint.large}) {
      ${({ fullWidth }) => fullWidth && 'width: 100%'};
    }
    ${fluidRange({
        prop: 'padding-left',
        fromSize: '1px',
        toSize: '15px'
      },
      '400px',
      '1800px'
    )}
    ${fluidRange({
        prop: 'padding-right',
        fromSize: '1px',
        toSize: '15px'
      },
      '400px',
      '1800px'
    )}
  }

  article {
    position: relative;
    max-width: 700px;
    ${fluidRange({
        prop: 'padding-top',
        fromSize: '40px',
        toSize: '130px'
      },
      '300px',
      '1600px'
    )}
    ${fluidRange({
        prop: 'padding-left',
        fromSize: '1px',
        toSize: '140px'
      },
      '400px',
      '1800px'
    )}
    ${fluidRange({
        prop: 'padding-right',
        fromSize: '1px',
        toSize: '140px'
      },
      '400px',
      '1800px'
    )}
    ${fluidRange({
        prop: 'padding-bottom',
        fromSize: '20px',
        toSize: '110px'
      },
      '500px',
      '1800px'
    )}

    &:after {
      display: block;
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: -50%;
      bottom: 0;
      right: 0;
      background: ${({ grey, theme }) => grey !== false &&
        theme.color.grey.light
      };
    }
  }

  article:first-child:after {
    left: -15px;
    right: -50%;
  }

  @media(max-width: ${({ theme }) => theme.breakpoint.large}) {
    flex-wrap: wrap;

    aside:first-child {
      order: 2;
    }

    article:after {
      bottom: -150px;
    }

    aside {
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
`

const FeatureBlock = ({ children, threshold, center, grey, fullWidth }) => {
  const containerRef = useRef(null)
  const [isInViewport, wrappedContainerRef] = useIsInViewport({
    target: containerRef,
    threshold: threshold || 30
  })
  const trigger = useTrigger(isInViewport)

  return (
    <Block ref={wrappedContainerRef} center={center} grey={grey} fullWidth={fullWidth}>
      {Children.map(children, child => cloneElement(child, { trigger }))}
    </Block>
  )
}

FeatureBlock.propTypes = {
  children: PropTypes.node,
  threshold: PropTypes.number,
  center: PropTypes.bool,
  grey: PropTypes.bool
}

export default FeatureBlock
