import React, { useContext } from 'react'
import Context from '../context'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { animated } from 'react-spring'
import useAnimatedMenu from '../hooks/useAnimatedMenu'

const Nav = styled(animated.nav)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 997;
  background: ${({ theme }) => theme.color.grey.light};
  padding-top: 100px;

  @media(min-width: ${({ theme }) => theme.breakpoint.large}) {
    display: none;
  }
`

const Li = styled(animated.li)`
  display: block;
  margin: 10px 0;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.dark};
  padding: 12px 0;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  display: inline-block;
  font-size: 28px;

  &.current {
    border-bottom: 2px solid ${({ theme }) => theme.color.grey.dark};
  }
`

const MobileMenu = ({ navItems }) => {
  const [{ showMenu }] = useContext(Context)
  const [animatedContainer, animatedItems] = useAnimatedMenu(navItems, showMenu)

  return (
    <Nav style={animatedContainer}>
      <ul>
        {animatedItems.map(({ item, key, props }) =>
          <Li key={key} style={props} >
            <StyledLink
              to={item.link}
              activeClassName='current'
            >
              {item.title}
            </StyledLink>
          </Li>
        )}
      </ul>
    </Nav>
  )
}

MobileMenu.propTypes = {
  containerSpring: PropTypes.object,
  navItems: PropTypes.array.isRequired
}

export default MobileMenu
