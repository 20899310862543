import { useState } from 'react'
import { config, useSpring } from 'react-spring'

const useAnimatedBurger = ({ dark, theme }) => {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => setShowMenu(prev => !prev)

  const one = useSpring({
    config: config.stiff,
    from: {
      backgroundColor: dark ? theme.color.white : theme.color.dark,
      transform: 'rotate(0deg)',
      marginTop: '0px',
      marginBottom: '6px'
    },
    to: showMenu ? [
      {
        backgroundColor: theme.color.dark,
        marginTop: '10px',
        marginBottom: '-2px'
      },
      { transform: 'rotate(45deg)' }
    ] : [
      {
        backgroundColor: dark ? theme.color.white : theme.color.dark,
        transform: 'rotate(0deg)'
      },
      {
        marginTop: '0px',
        marginBottom: '6px'
      }
    ]
  })

  const two = useSpring({
    config: config.stiff,
    from: {
      backgroundColor: dark ? theme.color.white : theme.color.dark,
      opacity: '1'
    },
    to: showMenu ? {
      backgroundColor: theme.color.dark,
      opacity: '0'
    } : [
      { backgroundColor: dark ? theme.color.white : theme.color.dark },
      { opacity: '1' }
    ]
  })

  const three = useSpring({
    config: config.stiff,
    from: {
      backgroundColor: dark ? theme.color.white : theme.color.dark,
      transform: 'rotate(0deg)',
      width: '70%',
      marginTop: '6px'
    },
    to: showMenu ? [
      {
        backgroundColor: theme.color.dark,
        width: '100%',
        marginTop: '-2px'
      },
      { transform: 'rotate(-45deg)' }
    ] : [
      {
        backgroundColor: dark ? theme.color.white : theme.color.dark,
        transform: 'rotate(0deg)'
      },
      {
        width: '70%',
        marginTop: '6px'
      }
    ]
  })

  return [
    { one, two, three },
    showMenu,
    toggleMenu
  ]
}

export default useAnimatedBurger
