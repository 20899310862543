import React from 'react'
import Headroom from 'react-headroom'
import PropTypes from 'prop-types'

const HeadroomFilter = ({
  onPin,
  onUnfix,
  upTolerance,
  downTolerance,
  children,
  className
}) => (
  <Headroom
    onPin={onPin}
    onUnfix={onUnfix}
    upTolerance={upTolerance}
    downTolerance={downTolerance}
    className={className}
  >
    {children}
  </Headroom>
)

HeadroomFilter.propTypes = {
  onPin: PropTypes.func,
  onUnfix: PropTypes.func,
  upTolerance: PropTypes.number,
  downTolerance: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string
}

export default HeadroomFilter
