import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const LinkFilter = ({
  to,
  className,
  children,
  activeClassName,
  onMouseOver
}) => (
  <Link
    to={to}
    className={className}
    activeClassName={activeClassName}
    onMouseOver={onMouseOver}
  >
    {children}
  </Link>
)

LinkFilter.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
  activeClassName: PropTypes.string,
  onMouseOver: PropTypes.func
}

export default LinkFilter
