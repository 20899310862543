import { useState, useEffect } from 'react'

const useTrigger = (isInViewport) => {
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    if (!trigger && isInViewport) setTrigger(true)
  }, [isInViewport])

  return trigger
}

export default useTrigger
