import React, { useRef } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useTrail, animated } from 'react-spring'
import styled from 'styled-components'
import useIsInViewport from 'use-is-in-viewport'
import useTrigger from '../hooks/useTrigger'
import Button from '../components/button'

const AnimatedBackground = animated(Img)

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .button {
    display: none;
  }

  &:hover .gatsby-image-wrapper {
    display: none;
  }

  &:hover .button {
    display: inline-block;
  }
`

const AnimatedLogo = styled(animated(Img))`
  width: 70%;
  height: auto;
`

const BrandsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Brand = styled(Link)`
  position: relative;
  width: 30%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    width: 40%;
  }

  .background {
    transition: 250ms all ease;
  }

  &:hover .background {
    position: relative;
    transform: scale(0.95);
    img {
      filter: grayscale(100%);
    }
  }

  &:hover .background:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(141, 94, 151, 0.6);
    content: '';
  }
`

const Brands = (props) => {
  const containerRef = useRef(null)
  const [isInViewport, wrappedContainerRef] = useIsInViewport({
    target: containerRef,
    threshold: 30,
  })
  const trigger = useTrigger(isInViewport)

  const brands = [
    { name: 'boadicea', link: '/brands/boadicea' },
    { name: 'horatio', link: '/brands/horatio' },
    { name: 'kolo', link: '/brands/kolo' },
    { name: 'nod', link: '/brands/nod' },
    { name: 'quintesscent', link: '/brands/quintesscent' },
    { name: 'shl', link: '/brands/shl' },
  ]

  const data = useStaticQuery(graphql`
    query {
      boadiceaBackground: file(
        relativePath: { eq: "brands/backgrounds/boadicea.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cppBackground: file(relativePath: { eq: "brands/backgrounds/cpp.png" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      horatioBackground: file(
        relativePath: { eq: "brands/backgrounds/horatio.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      koloBackground: file(
        relativePath: { eq: "brands/backgrounds/kolo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nodBackground: file(relativePath: { eq: "brands/backgrounds/nod.png" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      quintesscentBackground: file(
        relativePath: { eq: "brands/backgrounds/quintesscent.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rajaniBackground: file(
        relativePath: { eq: "brands/backgrounds/rajani.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shlBackground: file(relativePath: { eq: "brands/backgrounds/shl.png" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      boadiceaLogo: file(relativePath: { eq: "brands/altlogos/boadicea.png" }) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cppLogo: file(relativePath: { eq: "brands/altlogos/cpp.png" }) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      horatioLogo: file(relativePath: { eq: "brands/altlogos/horatio.png" }) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      koloLogo: file(relativePath: { eq: "brands/altlogos/kolo.png" }) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nodLogo: file(relativePath: { eq: "brands/altlogos/nod.png" }) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      quintesscentLogo: file(
        relativePath: { eq: "brands/altlogos/quintesscent.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rajaniLogo: file(relativePath: { eq: "brands/altlogos/rajani.png" }) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shlLogo: file(relativePath: { eq: "brands/altlogos/shl.png" }) {
        childImageSharp {
          fluid(maxWidth: 266, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const fadeIn = useTrail(brands.length, {
    opacity: trigger ? 1 : 0,
    x: trigger ? 0 : 20,
    height: trigger ? 80 : 0,
  })

  return (
    <BrandsContainer ref={wrappedContainerRef}>
      {fadeIn.map(({ x, height, ...rest }, index) => (
        <Brand key={index} to={brands[index].link}>
          <LogoContainer>
            <AnimatedLogo
              fluid={data[`${brands[index].name}Logo`].childImageSharp.fluid}
              style={{
                ...rest,
                transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
              }}
            />
            <Button fake className='button'>
              View Brand
            </Button>
          </LogoContainer>
          <div className='background'>
            <AnimatedBackground
              fluid={
                data[`${brands[index].name}Background`].childImageSharp.fluid
              }
              style={{
                ...rest,
                transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
              }}
            />
          </div>
        </Brand>
      ))}
    </BrandsContainer>
  )
}

export default Brands
