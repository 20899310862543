import React from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring, config } from 'react-spring'

const AnimatedArticle = ({ children, trigger, onLoad, className, badge }) => {
  const fadeIn = useSpring({
    config: config.molasses,
    opacity: (onLoad || trigger) ? '1' : '0',
    transform: (onLoad || trigger) ? 'translateY(0)' : 'translateY(50px)'
  })

  return (
    <article
      className={className}
      css={badge && 'margin-bottom: 250px;'}
    >
      <animated.div style={fadeIn}>
        {children}
      </animated.div>
    </article>
  )
}

AnimatedArticle.propTypes = {
  children: PropTypes.node,
  trigger: PropTypes.bool,
  onLoad: PropTypes.bool,
  className: PropTypes.string,
  badge: PropTypes.string
}

export default AnimatedArticle
