import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Context from '../context'
import { animated } from 'react-spring'
import useAnimatedBurger from '../hooks/useAnimatedBurger'

const StyledButton = styled.button`
  outline: none;
  position: absolute;
  right: 22px;
  z-index: 998;
  background: transparent;
  border: none;
  top: 50%;
  padding: 5px 5px 10px;
  transform: translateY(-50%);

  @media(min-width: ${({ theme }) => theme.breakpoint.large}) {
    display: none;
  }
`

const Burger = styled.div`
  width: 24px;
  height: 20px;

  & > span {
    display: block;
    height: 2px;
    width: 100%;
    margin: 0;

    &:nth-child(1) {
      margin-bottom: 6px;
    }

    &:nth-child(2) {
      width: 50%;
    }

    &:nth-child(3) {
      width: 70%;
      margin-top: 6px;
    }
  }
`
const MenuButton = ({ dark, theme }) => {
  const [context, setContext] = useContext(Context)
  const [burger, showMenu, toggleMenu] = useAnimatedBurger({ dark, theme })
  useEffect(() => setContext({ ...context, showMenu }), [showMenu])

  return (
    <StyledButton aria-label='Menu' title='Menu' onClick={toggleMenu}>
      <Burger>
        <animated.span style={burger.one} />
        <animated.span style={burger.two} />
        <animated.span style={burger.three} />
      </Burger>
    </StyledButton>
  )
}

MenuButton.propTypes = {
  dark: PropTypes.bool,
  theme: PropTypes.object
}

export default withTheme(MenuButton)
