import { useRef } from 'react'
import useLockBodyScroll from './useLockBodyScroll'
import { useSpring, useTransition, useChain, config } from 'react-spring'

const useAnimatedMenu = (navItems, showMenu) => {
  const { setLockBody } = useLockBodyScroll()
  const containerRef = useRef()
  const itemsRef = useRef()

  const animatedContainer = useSpring({
    ref: containerRef,
    config: config.default,
    from: { width: '0%' },
    width: showMenu ? '100%' : '0%',
    onRest: () => setLockBody(showMenu)
  })

  const animatedItems = useTransition(
    showMenu ? navItems : [],
    item => item.title, {
      ref: itemsRef,
      trail: 400 / navItems.length,
      from: { opacity: 0, transform: 'translateY(20px)' },
      enter: { opacity: 1, transform: 'translateY(0)' },
      leave: { opacity: 0, transform: 'translateY(20px)' },
      unique: true,
      reset: true
    }
  )

  useChain(
    showMenu ? [containerRef, itemsRef] : [itemsRef, containerRef],
    [0, 0.4]
  )

  return [animatedContainer, animatedItems]
}

export default useAnimatedMenu
