import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { darken } from 'polished'

const buttonStyle = ({ colour, theme, loading }) => `
  padding: 20px 35px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 400;
  transition: all 0.1s linear;
  cursor: pointer;
  border: none;
  display: inline-block;
  font-size: 0.9em;
  background: ${
    (colour === 'primary') ? theme.color.primary
    : (colour === 'secondary') ? theme.color.secondary
    : (colour === 'light') ? theme.color.white
    : (colour === 'dark') ? theme.color.dark
    : theme.color.grey.mediumLight
  };
  color: ${
    (colour === 'primary') ? theme.color.white
    : (colour === 'secondary') ? theme.color.dark
    : (colour === 'light') ? theme.color.dark
    : (colour === 'dark') ? theme.color.white
    : darken(0.1, theme.color.grey.mediumDark)
  };

  &:hover {
    background: ${
      (colour === 'primary') ? darken(0.025, theme.color.primary)
      : (colour === 'secondary') ? darken(0.1, theme.color.secondary)
      : (colour === 'light') ? theme.color.primary
      : (colour === 'dark') ? theme.color.primary
      : darken(0.05, theme.color.grey.mediumLight)
    };
    color: ${
      (colour === 'primary') ? theme.color.white
      : (colour === 'secondary') ? theme.color.dark
      : (colour === 'light') ? theme.color.white
      : (colour === 'dark') ? theme.color.white
      : darken(0.1, theme.color.grey.mediumDark)
    };
  }

  &:active {
    background: ${
      (colour === 'primary') ? darken(0.075, theme.color.primary)
      : (colour === 'secondary') ? darken(0.2, theme.color.secondary)
      : (colour === 'light') ? darken(0.1, theme.color.primary)
      : (colour === 'dark') ? darken(0.1, theme.color.primary)
      : darken(0.1, theme.color.grey.mediumLight)
    };
  }

  ${loading && `background-color: ${theme.color.grey.mediumLight};`}
`
const StyledButton = styled.button`${props => buttonStyle(props)}`
const StyledLink = styled(Link)`${props => buttonStyle(props)}`
const FakeButton = styled.div`${props => buttonStyle(props)}`

const Button = ({ colour, to, button, fake, children, className, loading }) => (
  <>
    {button
      ? <StyledButton colour={colour} loading={loading} className={className}>
        {children}
      </StyledButton>
      : fake
        ? <FakeButton colour={colour} loading={loading} className={className}>
          {children}
        </FakeButton>
        : <StyledLink
          colour={colour}
          to={to}
          loading={loading}
          className={className}
        >
          {children}
        </StyledLink>
    }
  </>
)

Button.defaultProps = {
  children: 'More Info'
}

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  button: PropTypes.bool,
  fake: PropTypes.bool,
  colour: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool
}

export default Button
