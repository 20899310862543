
export default (corner) => {
  if (corner === 'hero') {
    return `
      clip-path:
        polygon(
          0 50%, 30% 0,
          100% 0, 100% 0,
          100% 100%, 100% 100%,
          0% 100%, 0 100%
        );

      @media(max-width: 600px) {
        clip-path:
          polygon(
            0 50%, 80% 0,
            100% 0, 100% 0,
            100% 100%, 100% 100%,
            0% 100%, 0 100%
          );
      }
    `
  }

  if (corner === 'topLeft') {
    return `
      clip-path:
        polygon(
          0 40%, 50% 0,
          100% 0, 100% 0,
          100% 100%, 100% 100%,
          0% 100%, 0 100%
        );
    `
  }

  if (corner === 'topLeftSmall') {
    return `
      clip-path:
        polygon(
          0 30%, 30% 0,
          100% 0, 100% 0,
          100% 100%, 100% 100%,
          0% 100%, 0 100%
        );
    `
  }

  if (corner === 'topRight') {
    return `
      clip-path:
        polygon(
          0 0, 0 0,
          50% 0, 100% 40%,
          100% 100%, 100% 100%,
          0% 100%, 0 100%
        );
    `
  }

  if (corner === 'topRightSmall') {
    return `
      clip-path:
        polygon(
          0 0, 0 0,
          68% 0, 100% 20%,
          100% 100%, 100% 100%,
          0% 100%, 0 100%
        );
    `
  }

  if (corner === 'bottomRight') {
    return `
      clip-path:
        polygon(
          0 0, 0 0,
          100% 0, 100% 0,
          100% 60%, 60% 100%,
          0% 100%, 0 100%
        );
    `
  }

  if (corner === 'bottomLeft') {
    return `
      clip-path:
        polygon(
          0 0, 0 0,
          100% 0, 100% 0,
          100% 100%, 100% 100%,
          60% 100%, 0% 60%
        );
    `
  }

  if (corner === 'bottomLeftSmall') {
    return `
      clip-path:
        polygon(
          0 0, 0 0,
          100% 0, 100% 0,
          100% 100%, 100% 100%,
          30% 100%, 0% 75%
        );
    `
  }

  return `
    clip-path:
      polygon(
        0 0, 0 0,
        100% 0, 100% 0,
        100% 70%, 65% 100%,
        0% 100%, 0 100%
      );
  `
}
