import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fluidRange } from 'polished'

const Overflow = styled.div`
  overflow: ${({ allowOverflow }) => allowOverflow || 'hidden'};
  background: ${({ background }) => background};
  width: 100%;
`

const StyledContainer = styled.div`
  margin: 0 auto;
  ${fluidRange({
       prop: 'padding-left',
       fromSize: '15px',
       toSize: '32px'
     },
     '300px',
     '1600px'
   )}
   ${fluidRange({
       prop: 'padding-right',
       fromSize: '15px',
       toSize: '32px'
     },
     '300px',
     '1600px'
   )}

  ${({ noPadding }) => noPadding !== true && noPadding !== 'top' &&
    fluidRange({
        prop: 'padding-top',
        fromSize: '50px',
        toSize: '100px'
      },
      '300px',
      '1600px'
    )
  };

  ${({ noPadding }) => noPadding !== true && noPadding !== 'bottom' &&
    fluidRange({
        prop: 'padding-bottom',
        fromSize: '50px',
        toSize: '100px'
      },
      '300px',
      '1600px'
    )
  };

  ${({ fullWidth, customWidth, wide }) => !fullWidth && `
    max-width: ${customWidth || (wide ? 1500 : 950)}px;
  `}
`

const Container = ({
  wide,
  fullWidth,
  width,
  noPadding,
  children,
  overflow,
  background,
  className
}) => (
  <Overflow allowOverflow={overflow} background={background}>
    <StyledContainer
      wide={wide}
      fullWidth={fullWidth}
      noPadding={noPadding}
      customWidth={width}
      className={className}
    >
      {children}
    </StyledContainer>
  </Overflow>
)

Container.propTypes = {
  width: PropTypes.number,
  wide: PropTypes.bool,
  background: PropTypes.string,
  fullWidth: PropTypes.bool,
  overflow: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  noPadding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default Container
