import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Image = ({ fluid, fixed, alt, height, className }) => (
  <Img
    alt={alt}
    height={height}
    fluid={fluid}
    fixed={fixed}
    className={className}
  />
)

Image.propTypes = {
  height: PropTypes.number,
  fixed: PropTypes.object,
  fluid: PropTypes.object,
  alt: PropTypes.string,
  className: PropTypes.string
}

export default Image
