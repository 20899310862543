import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fluidRange, darken } from 'polished'
import { useSpring, animated } from 'react-spring'
import useMeasure from '../hooks/useMeasure'
import usePrevious from '../hooks/usePrevious'

const Dropdown = styled(animated.div)`
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 999;
  top: calc(100% + 15px);
  left: 0;
  right: 0;
  height: auto;
  overflow: hidden
`

const DropdownLink = styled(animated(Link))`
  display: block;
  color: ${({ theme }) => theme.color.dark};
  padding: 10px 15px;
  font-weight: 400;
  line-height: 1.6;
  ${fluidRange({
      prop: 'font-size',
      fromSize: '13px',
      toSize: '15px'
    },
    '300px',
    '1600px'
  )}

  &.current, &:hover {
    background: ${({ theme }) => theme.color.dark};
    color: ${({ theme }) => theme.color.white};
  }

  &:active {
    background: ${({ theme }) => darken(0.2, theme.color.dark)};
  }
`

const MenuDropdown = ({ parent, isOpen }) => {
  const previous = usePrevious(isOpen)
  const [ref, { height: viewHeight }] = useMeasure()
  const { height, transform } = useSpring({
    from: { height: 0, transform: 'translate3d(20px,0,0)' },
    height: isOpen ? viewHeight : 0,
    transform: `translate3d(${isOpen ? 0 : 10}px,0,0)`
  })

  return (
    <Dropdown style={{
      height: isOpen && previous === isOpen ? 'auto' : height
    }}>
      <animated.div {...ref}>
        {parent.children.map((child, i) =>
          <DropdownLink
            key={i}
            to={child.link}
            style={{ transform }}
            activeClassName='current'
          >
            {child.title}
          </DropdownLink>
        )}
      </animated.div>
    </Dropdown>
  )
}

MenuDropdown.propTypes = {
  parent: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
}

export default MenuDropdown
