import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import { useSpring, animated } from 'react-spring'
import Children from 'react-children-utilities'
import useScrollingText from '../hooks/useScrollingText'

const StyledTitle = styled.div`
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  line-height: ${({ fancy }) => fancy ? 1.2 : 1.8};
  color: ${({ theme }) => theme.color.dark};
  text-align: ${({ center }) => center && 'center'};
  margin-bottom: ${({ noMargin }) => noMargin || '2em'};
  margin: ${({ tight }) => tight && '0.5em 0'};
  ${({ from, to }) => fluidRange({
      prop: 'font-size',
      fromSize: from || '30px',
      toSize: to || '50px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'letter-spacing',
      fromSize: '0.05em',
      toSize: '0.15em'
    },
    '300px',
    '1600px'
  )}

  *:first-child {
    position: relative;
    z-index: 1;
  }

  h1, h2, h3, h4, h5, h6 {
    all: inherit;
  }
`

const ScrollingChild = styled(animated.span)`
  position: absolute;
  top: 50%;
  left: ${({ center }) => center ? '40%' : '20%'};
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '100px',
      toSize: '200px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange(
    {
      prop: 'letter-spacing',
      fromSize: '10px',
      toSize: '20px'
    },
    '300px',
    '1600px'
  )}
  font-weight: 600;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.mediumLight};
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: ${({ theme }) => theme.color.grey.mediumLight};
`

const Title = ({
  children,
  fancy,
  center,
  from,
  to,
  noMargin,
  tight,
  className
}) => {
  const [{ st }, set] = useSpring(() => ({ st: 0 }))
  const titleRef = useRef(null)
  useScrollingText(titleRef, set)

  return (
    <StyledTitle
      center={center}
      fancy={fancy}
      from={from}
      noMargin={noMargin}
      to={to}
      tight={tight}
      ref={titleRef}
      className={className}
    >
      <div>{children}</div>
      {fancy &&
        <ScrollingChild
          center={center}
          style={{
            transform: st.interpolate(o =>
              `translateY(-50%) translateX(-${o}%)`)
          }}
        >
          {Children.onlyText(children)}
        </ScrollingChild>
      }
    </StyledTitle>
  )
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  fancy: PropTypes.bool,
  center: PropTypes.bool,
  from: PropTypes.string,
  to: PropTypes.string,
  noMargin: PropTypes.bool,
  tight: PropTypes.bool,
  className: PropTypes.string
}

export default Title
