import { createGlobalStyle } from 'styled-components'
import { normalize, fluidRange, lighten } from 'polished'

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  select, input, textarea {
    font-size: 16px;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: ${({ theme }) => theme.font.body};
    color: ${({ theme }) => theme.color.grey.mediumDark};
    background: ${({ theme }) => theme.color.white};
    font-weight: ${({ theme }) => theme.font.weight};
    ${({ theme }) => fluidRange({
        prop: 'font-size',
        fromSize: theme.font.sizeMin,
        toSize: theme.font.size
      },
      '300px',
      '1600px'
    )}
  }

  p, ul {
    line-height: ${({ theme }) => theme.font.lineHeight};
    margin-bottom: 2em;
  }

  a {
    color: ${({ theme }) => lighten(0.1, theme.color.primary)};
    font-weight: 500;
    text-decoration: none;
  }

  ul {
    padding-left: 1.5em;
  }

  li {
    margin-bottom: 1em;
    list-style: none;
  }

  ul.list li::before {
    content: '';
    background: ${({ theme }) => lighten(0.1, theme.color.primary)};
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: calc(1.3em - 10px);
    margin-left: -1.3em;
    vertical-align: 1px;
  }

  ::selection {
    background: ${({ theme }) => lighten(0.3, theme.color.purple)};
    color: ${({ theme }) => theme.color.white};
  }
`

export default GlobalStyle
