import React, { useState, useContext } from 'react'
import Logo from './logo'
import Navigation from './navigation'
import MobileMenu from './mobile-menu'
import PropTypes from 'prop-types'
import GlobalStyle from '../styles/global'
import styled from 'styled-components'
import { fluidRange, darken } from 'polished'
import HeadroomFilter from '../filters/headroom'
import MenuButton from './menu-button'
import Context from '../context'
import { animated } from 'react-spring'
import useAnimation from '../hooks/useAnimation'

const StyledHeadroom = styled(HeadroomFilter)`
  position: relative;
  z-index: 999;
  width: 100%;
  min-height: 76px;

  ${({ absolute }) =>
    absolute &&
    `
    position: absolute;
    top: 0;
    left: 0;
  `}
`

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  ${fluidRange(
    {
      prop: 'padding-top',
      fromSize: '10px',
      toSize: '20px',
    },
    '300px',
    '1600px'
  )}
  ${fluidRange(
    {
      prop: 'padding-bottom',
      fromSize: '10px',
      toSize: '20px',
    },
    '300px',
    '1600px'
  )}
  ${fluidRange(
    {
      prop: 'padding-left',
      fromSize: '15px',
      toSize: '32px',
    },
    '300px',
    '1600px'
  )}
  ${fluidRange(
    {
      prop: 'padding-right',
      fromSize: '15px',
      toSize: '32px',
    },
    '300px',
    '1600px'
  )}

  ${({ pinned, dark, showMenu, theme }) =>
    pinned &&
    !showMenu &&
    `
    background: ${dark ? darken(0.1, theme.color.dark) : theme.color.white};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `}
`

const navItems = [
  { title: 'Bespoke', link: '/bespoke' },
  {
    title: 'Brands',
    link: '/brands',
    children: [
      { title: 'Boadicea the Victorious', link: '/brands/boadicea' },
      { title: 'Horatio', link: '/brands/horatio' },
      { title: 'Kolo', link: '/brands/kolo' },
      { title: 'NOD', link: '/brands/nod' },
      { title: 'Quintesscent', link: '/brands/quintesscent' },
      { title: 'Stephane Humbert Lucas', link: '/brands/shl' },
    ],
  },
  { title: 'About', link: '/about' },
  { title: 'Events', link: '/events' },
  { title: 'Contact', link: '/contact', button: true },
]

const Header = ({ dark, absolute }) => {
  const [pinned, setPinned] = useState(false)
  const [{ showMenu }] = useContext(Context)
  const { fadeIn } = useAnimation()

  return (
    <>
      <GlobalStyle />
      <StyledHeadroom
        onPin={() => setPinned(true)}
        onUnfix={() => setPinned(false)}
        upTolerance={15}
        downTolerance={20}
        absolute={absolute}
      >
        <animated.div style={fadeIn}>
          <StyledHeader pinned={pinned} dark={dark} showMenu={showMenu}>
            <Logo link='/' css='position: relative; z-index: 999;' />
            <MenuButton dark={dark} />
            <Navigation dark={dark} navItems={navItems} />
          </StyledHeader>
        </animated.div>
      </StyledHeadroom>
      <MobileMenu dark={dark} navItems={navItems} />
    </>
  )
}

Header.propTypes = {
  dark: PropTypes.bool,
  absolute: PropTypes.bool,
}

export default Header
