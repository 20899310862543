import { useEffect, useState } from 'react'

const useLockBodyScroll = () => {
  const [lockBody, setLockBody] = useState(false)

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (lockBody) document.body.style.overflow = 'hidden'
    return () => (document.body.style.overflow = originalStyle)
  }, [lockBody])

  return { setLockBody }
}

export default useLockBodyScroll
