import React from 'react'
import styled from 'styled-components'
import Logo from './logo'
import { fluidRange } from 'polished'
import { Link } from 'gatsby'

const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.grey.mediumLight};
  display: flex;
  align-items: center;
  font-size: 0.9em;
  ${fluidRange({
      prop: 'padding',
      fromSize: '15px',
      toSize: '25px'
    },
    '300px',
    '1600px'
  )}

  & > * {
    flex-grow: 1;
    flex-basis: 0;
  }

  a {
    color: inherit;
    font-weight: inherit;

    &:hover {
      border-bottom: 1px solid ${({ theme }) => theme.color.white};
    }
  }

  @media(max-width: 850px) {
    flex-wrap: wrap;
    text-align: center;
    flex-direction: column;

    & > * {
      flex-grow: 0;
      flex-basis: auto;
      margin: 20px 0;
    }
  }
`

const Copyright = styled.div`
  @media(max-width: 850px) {
    order: 2;
  }
`

const StyledLogo = styled(Logo)`
  height: 50px;

  @media(max-width: 850px) {
    order: 1;
  }
`

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(max-width: 850px) {
    order: 3;
    justify-content: center;
  }
`

const Footer = () => (
  <StyledFooter>
    <Copyright>
      &copy; {new Date().getFullYear()} Valorem Distribution |
      {' '}<Link to='/privacy'>Privacy Policy</Link>
    </Copyright>
    <StyledLogo />
    <RightColumn>
      <a
        href='https://sidigital.co'
        target='_blank'
        rel='noopener noreferrer'
      >
          Created by Si digital
      </a>
    </RightColumn>
  </StyledFooter>
)

export default Footer
