import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import logo from '../images/valorem-logo.svg'
import styled from 'styled-components'
import { fluidRange } from 'polished'

const Img = styled.img`
  height: auto;
  padding: 5px 0;
  ${fluidRange({
      prop: 'width',
      fromSize: '150px',
      toSize: '243px'
    },
    '300px',
    '1600px'
  )}
`

const Logo = ({ link, className }) => (
  <>
    {link
      ? <Link to={link}>
        <Img src={logo} alt='Valorem Distribution' className={className} />
      </Link>
      : <Img src={logo} alt='Valorem Distribution' className={className} />
    }
  </>
)

Logo.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string
}

export default Logo
