export default {
  font: {
    body: "'Lato', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    sizeMin: '14px',
    size: '17px',
    weight: 400,
    lineHeight: '200%'
  },

  color: {
    primary: '#8C704E',
    secondary: '#2B212C',

    gold: '#C49F71',

    white: '#FFFFFF',
    grey: {
      light: '#F9F9F9',
      mediumLight: '#E8E8E8',
      medium: '#909090',
      mediumDark: '#666666',
      dark: '#2B212C'
    },
    purple: '#443446',

    dark: '#2B212C',
    black: '#000000'
  },

  breakpoint: {
    extraLarge: '1200px',
    large: '1000px',
    medium: '800px'
  }
}
