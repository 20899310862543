import React from 'react'
import PropTypes from 'prop-types'
import Footer from '../components/footer'
import GlobalStyle from '../styles/global'
import { ContextProvider } from '../context'
import { ThemeProvider } from 'styled-components'
import theme from '../styles/theme'

const Layout = ({ children, fullHeight }) => (
  <ContextProvider>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle fullHeight={fullHeight} />
        {children}
        <Footer />
      </>
    </ThemeProvider>
  </ContextProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullHeight: PropTypes.bool
}

export default Layout
