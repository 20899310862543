import React from 'react'
import Img from '../filters/image'
import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import notchClip from '../styles/notch'

const NativeImg = styled.img`
  ${({ shadow }) => shadow === false ||
    'box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1)'};
  ${({ notch }) => notch && notchClip(notch)}
`

const GatsbyImg = styled(Img)`
  ${({ shadow }) => shadow === false ||
    'box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1)'};
  ${({ notch }) => notch && notchClip(notch)}
`

const GatsbyBackgroundImage = styled(BackgroundImage)`
  ${({ shadow }) => shadow === false ||
    'box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1)'};
  ${({ notch }) => notch && notchClip(notch)}
  height: 100%;
`

const Image = ({
  fluid,
  fixed,
  src,
  notch,
  background,
  shadow,
  height,
  alt,
  className
}) => {
  if (src) {
    return (
      <div className={className}>
        <NativeImg
          src={src}
          notch={notch}
          shadow={shadow}
          alt={alt}
        />
      </div>
    )
  }

  if (background) {
    return (
      <div className={className}>
        <GatsbyBackgroundImage
          fluid={fluid}
          fixed={fixed}
          notch={notch}
          shadow={shadow}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <GatsbyImg
        fluid={fluid}
        fixed={fixed}
        notch={notch}
        shadow={shadow}
        alt={alt}
        height={height}
      />
    </div>
  )
}

Image.propTypes = {
  fixed: PropTypes.object,
  fluid: PropTypes.object,
  alt: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string,
  background: PropTypes.bool,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  notch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default Image
