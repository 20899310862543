import React from 'react'
import Link from '../filters/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import useHover from '../hooks/useHover'
import Button from './button'
import Dropdown from './menu-dropdown'
import { animated, useSpring } from 'react-spring'

const Li = styled.li`
  position: relative;
  display: inline-block;
  margin: 10px 0;
`

const StyledLink = styled(animated(Link))`
  position: relative;
  background: ${({ menuDropOpen, theme }) => menuDropOpen && theme.color.white};
  color: ${({ dark, menuDropOpen, theme }) =>
    dark && !menuDropOpen ? 'white' : theme.color.dark
  };
  padding: 15px 25px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  ${fluidRange({
      prop: 'font-size',
      fromSize: '13px',
      toSize: '15px'
    },
    '300px',
    '1600px'
  )}

  &.current {
    border-bottom: 2px solid ${({ theme, dark }) => dark
      ? theme.color.grey.light
      : theme.color.grey.dark
    };
  }
`

const Underline = styled(animated.div)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 2px;
  background: ${({ theme, style }) => style.dark
    ? theme.color.grey.light
    : theme.color.grey.dark
  };
`

const Caret = styled.span`
  display: inline-block;
  vertical-align: 3px;
  border: solid ${({ dark, menuDropOpen, theme }) =>
    dark && !menuDropOpen ? 'white' : theme.color.dark
  };
  border-width: 0 2px 2px 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 8px;
  transition: 250ms transform ease;
  transform: rotate(45deg);
  transform-origin: 7px 7px;
  ${({ menuDropOpen }) => menuDropOpen && `
    transform: rotate(-135deg);
  `}
`

const MenuItem = ({ dark, navItem }) => {
  const [hover, toggleHover] = useHover()
  const onMouseOver = () => toggleHover(true)
  const onMouseLeave = () => toggleHover(false)

  const hoverStyle = useSpring({
    config: { tension: 210, friction: 25 },
    left: hover ? '0%' : '50%',
    width: hover ? '100%' : '0%'
  })

  return (
    <Li onMouseLeave={onMouseLeave} onMouseOver={onMouseOver}>
      {navItem.button
        ? <Button
          to={navItem.link}
          colour={dark ? 'light' : 'dark'}
          css={'margin-left: 20px;'}
        >
          {navItem.title}
        </Button>
        : <>
          <StyledLink
            to={navItem.link}
            dark={dark}
            menuDropOpen={hover && navItem.children}
            activeClassName='current'
          >
            {navItem.title}
            {navItem.children
              ? <Caret menuDropOpen={hover} dark={dark} />
              : <Underline style={{ ...hoverStyle, dark }} />
            }
          </StyledLink>
          {navItem.children &&
            <Dropdown parent={navItem} isOpen={hover} />
          }
        </>
      }
    </Li>
  )
}

MenuItem.propTypes = {
  dark: PropTypes.bool,
  navItem: PropTypes.object.isRequired
}

export default MenuItem
